import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Pagination from '@mui/material/Pagination';
import { styled } from '@mui/material';

interface User {
    username: string;
    imageURL?: string;
    lastWeekScore: number;
}

const CustomTableContainer = styled(TableContainer)({
    backgroundColor: 'rgb(239,239,239)',
    border: '1px solid rgb(79, 79, 79)',
    borderRadius: '16px',
    marginTop: '20px',
    '&:last-child': {
        borderBottom: 'none'
    }
});

const CustomTableCell = styled(TableCell)({
    color: 'black',
    borderBottom: '1px solid rgb(79, 79, 79)',
});

const CustomTableRow = styled(TableRow)({
    borderBottom: '1px solid rgb(79, 79, 79)',
});

const CustomTableHead = styled(TableHead)({
    backgroundColor: 'rgb(239,239,239)',
    borderBottom: '1px solid rgb(0,0,0)',
});

const UserTable: React.FC = () => {
    const formatNumber = (num: number) => {
        return new Intl.NumberFormat('en-US').format(num);
    };

    const [users, setUsers] = useState<User[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchUsers = async (page: number) => {
            setLoading(true);
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/users-with-score`, {
                    params: {
                        page,
                        limit: 50,  // Set the limit per page (50 users per page)
                    },
                    withCredentials: true,
                });

                setUsers(response.data.users);
                setCurrentPage(response.data.currentPage);
                setTotalPages(response.data.totalPages);
            } catch (error) {
                console.error('Error fetching users:', error);
                setUsers([]);
            } finally {
                setLoading(false);
            }
        };

        fetchUsers(currentPage);
    }, [currentPage]);

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setCurrentPage(value);
    };

    return (
        <>
            <CustomTableContainer>
                <Table>
                    <CustomTableHead>
                        <CustomTableRow>
                            <CustomTableCell>Ranking</CustomTableCell>
                            <CustomTableCell>Username</CustomTableCell>
                            <CustomTableCell>Score</CustomTableCell>
                        </CustomTableRow>
                    </CustomTableHead>
                    <TableBody>
                        {loading ? (
                            <CustomTableRow>
                                <CustomTableCell colSpan={3}>Loading...</CustomTableCell>
                            </CustomTableRow>
                        ) : (
                            users.map((user, index) => (
                                <CustomTableRow key={user.username}>
                                    <CustomTableCell>{'# ' + (index + 1 + (currentPage - 1) * 50)}</CustomTableCell>
                                    <CustomTableCell>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            {user.imageURL && (
                                                <img
                                                    src={user.imageURL}
                                                    alt={user.username}
                                                    style={{
                                                        width: '25px',
                                                        height: '25px',
                                                        borderRadius: '50%',
                                                        marginRight: '10px'
                                                    }}
                                                />
                                            )}
                                            <a href={'https://twitter.com/' + user.username} target="_blank" style={{ textDecoration: 'none' }}>
                                                <span style={{ textDecoration: 'underline', color: 'black' }}> {'@' + user.username}</span>
                                            </a>
                                        </div>
                                    </CustomTableCell>
                                    <CustomTableCell>{formatNumber(user.lastWeekScore)}</CustomTableCell>
                                </CustomTableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </CustomTableContainer>

            {/* Pagination Controls */}
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePageChange}
                    variant="outlined"
                    shape="rounded"
                />
            </div>
        </>
    );
};

export default UserTable;
