/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';


const listStyles = css`
  display: flex;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
  text-align: left;
  padding: 8px 16px;
  `;

const listIconStyle = css`
  min-width: 56px;
  color: rgb(0,0,0);
  flex-shrink: 0;
  display: inline-flex;`;

const listContentStyle = css`
  flex: 1 1 auto;
  min-width: 0px;
  margin-top: 6px;
  margin-bottom: 6px;`;

const listContentSpanStyle = css`
  margin: 0px;
  font-family: "IBM Plex Mono";
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  display: block;`;

const listContentPStyle = css`
  margin: 0px;
  font-family: "IBM Plex Mono";
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  color: rgba(0,, 0, 0, 0.7);
  display: block;`;


const svgIconStyle = css`
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentcolor;
  flex-shrink: 0;
  font-size: 1.5rem;`

export const MultipliersList = () => {

    return (
        <ul css={css`margin-top: 16px;padding: 8px 0px;`}>
            <li css={listStyles}>
                <div css={listIconStyle}>
                    <svg css={svgIconStyle} viewBox="0 0 24 24">
                        <path d ="M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-2
                        12H6v-2h12v2zm0-3H6V9h12v2zm0-3H6V6h12v2z"/>
                    </svg>
                </div>
                <div css={listContentStyle}>
                    <span css={listContentSpanStyle}>Original Tweet</span>
                    <p css={listContentPStyle}>3x point multiplier</p>
                </div>
            </li>
            <li css={listStyles}>
                <div css={listIconStyle}>
                    <svg css={svgIconStyle} viewBox="0 0 24 24">
                        <path d = "M8 8H6v7c0 1.1.9 2 2 2h9v-2H8V8z"/>
                        <path d="M20 3h-8c-1.1 0-2 .9-2 2v6c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 8h-8V7h8v4zM4 12H2v7c0 1.1.9 2 2 2h9v-2H4v-7z"></path>
                    </svg>
                </div>
                <div css={listContentStyle}>
                    <span css={listContentSpanStyle}>Original Quote</span>
                    <p css={listContentPStyle}>2x point multiplier</p>
                </div>
            </li>
            <li css={listStyles}>
                <div css={listIconStyle}>
                    <svg css={svgIconStyle} viewBox="0 0 24 24">
                        <path d = "M10 9V5l-7 7 7 7v-4.1c5 0 8.5 1.6 11 5.1-1-5-4-10-11-11z"/>
                    </svg>
                </div>
                <div css={listContentStyle}>
                    <span css={listContentSpanStyle}>Original Reply</span>
                    <p css={listContentPStyle}>1x point multiplier</p>
                </div>
            </li>
            <li css={listStyles}>
                <div css={listIconStyle}>
                    <svg css={svgIconStyle} viewBox="0 0 24 24">
                        <path d="M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2M8
                        14H6v-2h2zm0-3H6V9h2zm0-3H6V6h2zm7 6h-5v-2h5zm3-3h-8V9h8zm0-3h-8V6h8z"></path>
                    </svg>
                </div>
                <div css={listContentStyle}>
                    <span css={listContentSpanStyle}>Replies, Quotes, Retweets of content from the official
                        ADYO account:</span>
                    <p css={listContentPStyle}>4x point multiplier</p>
                </div>
            </li>
        </ul>
    )
}