/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export const hrStyle= css`
  margin-top: 64px;
  flex-shrink: 0;
  width: 100%;
  border-width: 0px 0px thin;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.4);
  align-self: stretch;
  height: auto;`;

export const mainContentStyles = css`
  display: flex;
  overflow: hidden auto;
  -webkit-box-pack: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 96px;
  padding-left: 32px;
  padding-right: 32px;
  width: 100%;
  text-align: center;`;