/** @jsxImportSource @emotion/react */
import { FC } from 'react';
import { Helmet } from 'react-helmet';
import Navbar from '../components/Navbar';
import { Box, Typography } from '@mui/material';
import { css } from '@emotion/react';
import Footer from "../components/Footer";

const Legal: FC = () => {
  return (
    <div>
      <Helmet>
        <title>Legal</title>
        <link rel="canonical" href="https://post2earn.co.uk/legal" />
      </Helmet>
      <Navbar />
      <Typography
        variant="h4"
        css={css`
          text-transform: uppercase;
          text-align: center;
          margin-top: 100px;
          margin-bottom: 20px;
        `}
      >
        Legal
      </Typography>
      <div css={css`display: flex;flex-direction: column`}>
      <Box
        css={css`
          height: 78vh;
          overflow-y: auto;
          margin: 20px auto;
          padding: 20px;
          width: 70%;
        `}
      >
        <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
          Terms and Conditions
        </Typography>
        <Typography variant="body1" paragraph>
          BY PARTICIPATING IN THIS CAMPAIGN, INTERACTING WITH OUR WEBSITE, OR ENGAGING WITH OUR SOCIAL MEDIA ACCOUNTS, YOU ACKNOWLEDGE THAT THIS CAMPAIGN IS PART OF AN EXPERIMENTAL INITIATIVE RUN BY THE ADYO PROTOCOL. THE PURPOSE OF THE CAMPAIGN IS TO ENCOURAGE SOCIAL INTERACTION AND BUILD AWARENESS AROUND OUR PLATFORM. PARTICIPANTS EARN POINTS BY USING SPECIFIC HASHTAGS ON SOCIAL MEDIA, WHICH ARE TRACKED AND DISPLAYED ON OUR LEADERBOARD. THESE POINTS DO NOT REPRESENT ANY MONETARY VALUE ON THEIR OWN.
        </Typography>
        <Typography variant="body1" paragraph>
          AT THE END OF THE CAMPAIGN, THESE POINTS WILL BE CONVERTED INTO REAL TOKENS, WHICH MAY CARRY VALUE TIED TO FUTURE CASH FLOWS ON OUR PLATFORM. HOWEVER, PARTICIPATION IS ENTIRELY VOLUNTARY, AND NO PRIOR INVESTMENT OR MONETARY CONTRIBUTION IS REQUIRED. THE TOKENS ARE DISTRIBUTED FOR FREE, AND THEY ARE NOT OFFERED AS AN INVESTMENT PRODUCT.
        </Typography>
        <Typography variant="body1" paragraph>
          THE ADYO PROTOCOL IS AN EXPERIMENTAL BLOCKCHAIN-BASED INITIATIVE, AND WHILE THE TOKENS MAY HAVE POTENTIAL VALUE, THERE IS NO GUARANTEE THAT THEY WILL SUCCEED IN GENERATING ANY FUTURE ECONOMIC BENEFIT. THE NATURE OF THE CRYPTOCURRENCY MARKET IS VOLATILE AND UNCERTAIN, AND AS SUCH, PARTICIPANTS SHOULD UNDERSTAND THAT THESE ASSETS MAY FAIL TO BRING ANY VALUE.
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
          Agreement between User and post2earn.co.uk.
        </Typography>
        <Typography variant="body1" paragraph>
          Welcome to post2earn.co.uk. The post2earn.co.uk website (the "Site") is comprised of various web pages operated by AdYo ("AdYo"). post2earn.co.uk is offered to you conditioned on your acceptance without modification of the terms, conditions, and notices contained herein (the "Terms"). Your use of post2earn.co.uk constitutes your agreement to all such Terms. Please read these terms carefully, and keep a copy of them for your reference.
        </Typography>
        <Typography variant="body1" paragraph>
          post2earn.co.uk is a Marketing Campaign Site.
        </Typography>
        <Typography variant="body1" paragraph>
          We give users points for social interactions and engaging with other users.
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
          Privacy
        </Typography>
        <Typography variant="body1" paragraph>
          Your use of post2earn.co.uk is subject to post2earn.co.uk website’s Privacy Policy. Please review our Privacy Policy, which also governs the Site and informs users of our data collection practices.
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
          Electronic Communications
        </Typography>
        <Typography variant="body1" paragraph>
          Visiting post2earn.co.uk or sending emails to AdYo constitutes electronic communications. You consent to receive electronic communications and you agree that all agreements, notices, disclosures and other communications that we provide to you electronically, via email and on the Site, satisfy any legal requirement that such communications be in writing.
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
          Children Under Thirteen
        </Typography>
        <Typography variant="body1" paragraph>
          AdYo does not knowingly collect, either online or offline, personal information from persons under the age of thirteen. If you are under 18, you may use post2earn.co.uk only with permission of a parent or guardian.
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
          Links to Third Party Sites/Third Party Services
        </Typography>
        <Typography variant="body1" paragraph>
          post2earn.co.uk may contain links to other third-party web sites or services ("Linked Sites"). The Linked Sites are not under the control of AdYo and AdYo is not responsible for the contents of any Linked Site, including without limitation any link contained in a Linked Site, or any changes or updates to a Linked Site. AdYo is providing these links to you only as a convenience, and the inclusion of any link does not imply endorsement by AdYo of the site or any association with its operators.
        </Typography>
        <Typography variant="body1" paragraph>
          Certain services made available via post2earn.co.uk are delivered by third party sites and organizations (such as Twitter/X). By using any product, service or functionality originating from the post2earn.co.uk domain, you hereby acknowledge and consent that AdYo may share such information and data with any third party with whom AdYo has a contractual relationship to provide the requested product, service or functionality on behalf of post2earn.co.uk users and customers.
        </Typography>
        <Typography variant="body1" paragraph>
          You further acknowledge and agree that AdYo shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods, or services available on or through any Linked Sites. We strongly advise you to read the terms and conditions and privacy policies of any third-party web sites or services that you visit.
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
          No Unlawful or Prohibited Use/Intellectual Property
        </Typography>
        <Typography variant="body1" paragraph>
          You are granted a non-exclusive, non-transferable, revocable license to access and use post2earn.co.uk strictly in accordance with these terms of use. As a condition of your use of the Site, you warrant to AdYo that you will not use the Site for any purpose that is unlawful or prohibited by these Terms. You may not use the Site in any manner which could damage, disable, overburden, or impair the Site or interfere with any other party's use and enjoyment of the Site. You may not obtain or attempt to obtain any materials or information through any means not intentionally made available or provided for through the Site.
        </Typography>
        <Typography variant="body1" paragraph>
          All content included as part of the Service, such as text, graphics, logos, images, as well as the compilation thereof, and any software used on the Site, is the property of AdYo or its suppliers and protected by copyright and other laws that protect intellectual property and proprietary rights. You agree to observe and abide by all copyright and other proprietary notices, legends or other restrictions contained in any such content and will not make any changes thereto.
        </Typography>
        <Typography variant="body1" paragraph>
          You will not modify, publish, transmit, reverse engineer, participate in the transfer or sale, create derivative works, or in any way exploit any of the content, in whole or in part, found on the Site. AdYo content is not for resale. Your use of the Site does not entitle you to make any unauthorized use of any protected content, and in particular you will not delete or alter any proprietary rights or attribution notices in any content. You will use protected content solely for your personal use, and will make no other use of the content without the express written permission of AdYo and the copyright owner. You agree that you do not acquire any ownership rights in any protected content. We do not grant you any licenses, express or implied, to the intellectual property of AdYo or our licensors except as expressly authorized by these Terms.
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
          International Users
        </Typography>
        <Typography variant="body1" paragraph>
          You are responsible for compliance with all local laws of the location you access the Service. You agree that you will not use content accessed through post2earn.co.uk in any country or in any manner prohibited by any applicable laws, restrictions or regulations.
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
          Indemnification
        </Typography>
        <Typography variant="body1" paragraph>
          You agree to indemnify, defend and hold harmless AdYo, its officers, directors, employees, agents and third parties, for any losses, costs, liabilities and expenses (including reasonable attorney's fees) relating to or arising out of your use of or inability to use the Site or services, any user postings made by you, your violation of any terms of this Agreement or your violation of any rights of a third party, or your violation of any applicable laws, rules or regulations. AdYo reserves the right, at its own cost, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will fully cooperate with AdYo in asserting any available defenses.
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
          Governing Law
        </Typography>
        <Typography variant="body1" paragraph>
          These Terms shall be governed and construed in accordance with the laws of England and Wales, without regard to its conflict of law provisions.
        </Typography>
        <Typography variant="body1" paragraph>
          Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service, and supersede and replace any prior agreements we might have between us regarding the Service.
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
          Class Action Waiver
        </Typography>
        <Typography variant="body1" paragraph>
          Any arbitration under these Terms and Conditions will take place on an individual basis; class arbitrations and class/representative/collective actions are not permitted. THE PARTIES AGREE THAT A PARTY MAY BRING CLAIMS AGAINST THE OTHER ONLY IN EACH INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PUTATIVE CLASS, COLLECTIVE AND/ OR REPRESENTATIVE PROCEEDING, SUCH AS IN THE FORM OF A PRIVATE ATTORNEY GENERAL ACTION AGAINST THE OTHER. Further, unless both you and AdYo agree otherwise, the arbitrator may not consolidate more than one person's claims, and may not otherwise preside over any form of a representative or class proceeding.
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
          Liability Disclaimer
        </Typography>
        <Typography variant="body1" paragraph>
          THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR AVAILABLE THROUGH THE SITE MAY INCLUDE INACCURACIES OR TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE INFORMATION HEREIN. ADYO AND/OR ITS SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR CHANGES IN THE SITE AT ANY TIME.
        </Typography>
        <Typography variant="body1" paragraph>
          ADYO AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, AND ACCURACY OF THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS CONTAINED ON THE SITE FOR ANY PURPOSE. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ALL SUCH INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS ARE PROVIDED "AS IS" WITHOUT WARRANTY OR CONDITION OF ANY KIND. ADYO AND/OR ITS SUPPLIERS HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS WITH REGARD TO THIS INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS, INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.
        </Typography>
        <Typography variant="body1" paragraph>
          TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL ADYO AND/OR ITS SUPPLIERS BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE, DATA OR PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OR PERFORMANCE OF THE SITE, WITH THE DELAY OR INABILITY TO USE THE SITE OR RELATED SERVICES, THE PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS OBTAINED THROUGH THE SITE, OR OTHERWISE ARISING OUT OF THE USE OF THE SITE, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF ADYO OR ANY OF ITS SUPPLIERS HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE SITE, OR WITH ANY OF THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SITE.
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
          Termination/Access Restriction
        </Typography>
        <Typography variant="body1" paragraph>
          AdYo reserves the right, in its sole discretion, to terminate your access to the Site and the related services or any portion thereof at any time, without notice.
        </Typography>
        <Typography variant="body1" paragraph>
          You agree that no joint venture, partnership, employment, or agency relationship exists between you and AdYo as a result of this agreement or use of the Site. AdYo’s performance of this agreement is subject to existing laws and legal process, and nothing contained in this agreement is in derogation of AdYo's right to comply with governmental, court and law enforcement requests or requirements relating to your use of the Site or information provided to or gathered by AdYo with respect to such use. If any part of this agreement is determined to be invalid or unenforceable pursuant to applicable law including, but not limited to, the warranty disclaimers and liability limitations set forth above, then the invalid or unenforceable provision will be deemed superseded by a valid, enforceable provision that most closely matches the intent of the original provision and the remainder of the agreement shall continue in effect.
        </Typography>
        <Typography variant="body1" paragraph>
          Unless otherwise specified herein, this agreement constitutes the entire agreement between the user and AdYo with respect to the Site and it supersedes all prior or contemporaneous communications and proposals, whether electronic, oral or written, between the user and AdYo with respect to the Site. A printed version of this agreement and of any notice given in electronic form shall be admissible in judicial or administrative proceedings based upon or relating to this agreement to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form. It is the express wish to the parties that this agreement and all related documents be written in English.
        </Typography>
        <Typography variant="body1" paragraph>
          All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of liability.
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
          Changes to Terms
        </Typography>
        <Typography variant="body1" paragraph>
          AdYo reserves the right, in its sole discretion, to change the Terms under which post2earn.co.uk is offered. The most current version of the Terms will supersede all previous versions. AdYo encourages you to periodically review the Terms to stay informed of our updates.
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
          Contact Us
        </Typography>
        <Typography variant="body1" paragraph>
          AdYo welcomes your questions or comments regarding the Terms:<br />
          AdYo<br />
          Email: adyoteam@gmail.com<br />
          Paris, France<br />
          Effective as of October 05, 2024
        </Typography>
      </Box>
      </div>
    </div>
  );
};

export default Legal;