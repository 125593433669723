/** @jsxImportSource @emotion/react */
import { FC } from 'react';
import { Helmet } from 'react-helmet';
import Navbar from '../components/Navbar';
import { Box, Typography } from '@mui/material';
import { css } from '@emotion/react';

const Privacy: FC = () => {
    return (
        <div>
            <Helmet>
                <title>Privacy</title>
                <link rel="canonical" href="https://post2earn.co.uk/privacy"/>
            </Helmet>
            <Navbar/>
            <Typography
                variant="h4"
                css={css`
                  text-transform: uppercase;
                  text-align: center;
                  margin-top: 100px;
                  margin-bottom: 20px;
                `}
            >
                Privacy
            </Typography>
            <div css={css`display: flex;flex-direction: column`}>
            <Box
                css={css`
                  height: 78vh;
                  overflow-y: auto;
                  margin: 20px auto;
                  padding: 20px;
                  width: 70%;
                `}
            >
                <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
                    Privacy Policy
                </Typography>
                <Typography variant="body1" paragraph>
                    AdYo is committed to protecting and respecting your privacy. This Privacy Policy outlines how we collect, use, disclose, and protect your personal information when you visit our website or engage with our services.
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
                    Information We Collect
                </Typography>
                <Box sx={{ marginLeft: 3 }}>
                    <Typography variant="body1" paragraph>
                        1. Information You Provide: When you interact with us, you may provide us with personal information such as your name, email address, phone number, and any other information you choose to share;
                    </Typography>
                    <Typography variant="body1" paragraph>
                        2. Automatically Collected Information: We may automatically collect certain information about your visit to our website, including your IP address, browser type, operating system, referral source, and other usage details;
                    </Typography>
                    <Typography variant="body1" paragraph>
                        3. Cookies: Our website uses cookies to enhance your experience. You can choose to accept or decline cookies through your browser settings. Service or a third-party service.
                    </Typography>
                </Box>
                <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
                    How We Use Your Information
                </Typography>
                <Box sx={{ marginLeft: 3 }}>
                    <Typography variant="body1" paragraph>
                        1. To provide and improve our services to you.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        2. To communicate with you about our services, promotions, and updates.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        3. To analyze trends, administer our website, and gather demographic information.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        4. To comply with legal requirements and protect our rights.
                    </Typography>
                </Box>
                <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
                    Disclosure of Your Information
                </Typography>
                <Box sx={{ marginLeft: 3 }}>
                    <Typography variant="body1" paragraph>
                        1. With service providers who assist us in operating our website and providing our services;
                    </Typography>
                    <Typography variant="body1" paragraph>
                        2. With our business partners for joint marketing efforts;
                    </Typography>
                    <Typography variant="body1" paragraph>
                        3. With law enforcement agencies or governmental bodies when required by law.
                    </Typography>
                </Box>
                <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
                    Data Security
                </Typography>
                <Typography variant="body1" paragraph>
                    We take appropriate measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction.
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
                    Your rights
                </Typography>
                <Typography variant="body1" paragraph>
                    You have the right to:
                </Typography>
                <Box sx={{ marginLeft:3 }}>
                    <Typography variant="body1" paragraph>
                        1. Access and update your personal information.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        2. Request that we delete your personal information.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        3. Opt-out of receiving marketing communications from us.
                    </Typography>
                </Box>
                <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
                    Changes to This Privacy Policy
                </Typography>
                <Typography variant="body1" paragraph>
                    We may update this Privacy Policy from time to time. Any changes will be posted on this page with a revised effective date.
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
                    Contact Us
                </Typography>
                <Typography variant="body1" paragraph>
                    If you have any questions or concerns about our Privacy Policy or our practices regarding your personal information, please contact us at adyoteam@gmail.com. By using our website and services, you consent to the terms of this Privacy Policy.
                </Typography>
            </Box>
            </div>
        </div>
    )
};

export default Privacy;