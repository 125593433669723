/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef, useState } from 'react';
import { css } from '@emotion/react';
import axios from 'axios';
import {Box, Button, Typography} from '@mui/material';
import {loginButtonStyle} from '../styles/ButtonStyles';
import {useUserProfile} from "../contexts/UserContext";
import {CircularProgress} from "@mui/material";
import { useNavigate } from 'react-router-dom';
import {blueGrey} from "@mui/material/colors";
import {useLocation} from "react-router";

const overlayStyle = css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1300;
  flex-direction: column;
`;

const popupStyle = css`
  display: flex;
  flex-direction: column;
  background: rgb(18, 18, 18);
  color: rgb(255, 255, 255);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border: 1px solid rgba(255, 255, 255, 0.12);
  border-radius: 16px;
  padding: 20px;
  z-index: 1400;
`;

const closeButtonStyle = css`
    ${loginButtonStyle};
    width: fit-content;
    height: 40px;
    align-self: center;
`;

const errorStyle = css`
    color: red;
`;

const logoutBoxStyle = css`
  margin-top: 10px;
  padding: 10px;
  border: 1px solid rgba(255, 255, 255, 0.12);
  border-radius: 16px;
  background: rgb(18, 18, 18);
  text-align: center;
`;

// Mocked profile data for testing
const mockProfile = {
    walletActivated: true, // Change this to false to simulate not connected
    canCalculatePoints: true, // Change this to false to simulate cooldown
    currentScore: 1000,
    currentRanking: 5,
    lastWeekScore: 200,
    id: 'user123',
    imageURL: 'ada',
    nextResetTime: '2024-08-12T00:00:00Z'
};

interface PopupProps {
    onClose: () => void;
}

const Popup: React.FC<PopupProps> = ({ onClose }) => {
    const overlayRef = useRef<HTMLDivElement | null>(null);
    const logOutRef = useRef<HTMLDivElement | null>(null);
    const location = useLocation();
    const { profile, updateProfile } = useUserProfile();
    //const [profile, updateProfile] = useState(mockProfile);
    const [isCalculating, setIsCalculating] = useState(false);
    const [cooldownTime, setCooldownTime] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const queryParams = new URLSearchParams(location.search);
    const errorMessageLogin = queryParams.get('error');


    const navigate = useNavigate();

    const loginTwitter = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/auth-url`, { withCredentials: true });
            const { url } = response.data;
            window.location.href = url;  // Perform the redirection
        } catch (error) {
            console.error('Error fetching auth URL:', error);
        }
    };

    const logout = async () => {
        try {
            await axios.post(`${process.env.REACT_APP_API_BASE_URL}/logout`, {}, { withCredentials: true });
            updateProfile({
            }); // Clear the profile from context
            window.location.reload(); // Reload the page or navigate to the home page
        } catch (error) {
            console.error('Error logging out:', error);
        }
    };

    const connectMetamask = async () => {
        if (typeof window.ethereum !== 'undefined') {
            try {
                const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
                const walletAddress = accounts[0];
                const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/connect-metamask`, { walletAddress },
                    { withCredentials: true });
                // updateProfile({ walletActivated: true });
            } catch (error: any) {
                if (error.response && error.response.status === 409) {
                    setErrorMessage('Metamask wallet address is already in use.');
                } else {
                    console.error('Error connecting to Metamask:', error);
                }
            }
        } else {
            console.error('Metamask not detected');
        }
    };

    const calculatePoints = async () => {
        setIsCalculating(true);
        setErrorMessage(null);
        updateProfile({canCalculatePoints:false})
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/calculate-points`, {},
                { withCredentials: true });
            //updateProfile({ currentScore: response.data.score });
            navigate('/stats');
        } catch (error) {
            setErrorMessage('Error calculating points. Please try again later.');
            console.error('Error calculating points:', error);
        } finally {
            setIsCalculating(false);
            //updateProfile({ canCalculatePoints: false });
        }
    };

    useEffect(() => {
        if (errorMessageLogin) {
            alert(`Error: ${decodeURIComponent(errorMessageLogin)}`);
            console.log(document.cookie);
        }
    }, [errorMessageLogin]);

    useEffect(() => {
        // Cooldown logic
        if (profile && profile.cooldown) {
            const interval = setInterval(() => {
                const now = new Date();
                const cooldownEnd = new Date(profile.cooldown);
                const distance = cooldownEnd.getTime() - now.getTime();
                console.log(distance);
                if (distance <= 0) {
                    clearInterval(interval);
                    setCooldownTime(null);
                    updateProfile({canCalculatePoints:true})
                } else {
                    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

                    setCooldownTime(`${hours}h ${minutes}m ${seconds}s`);
                }
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [profile?.cooldown]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                overlayRef.current &&
                !overlayRef.current.contains(event.target as Node) &&
                (!logOutRef.current || (logOutRef.current && !logOutRef.current.contains(event.target as Node)))

            ) {
                onClose();
            }
        };

        const disableScroll = () => {
            document.body.style.overflow = 'hidden';
        };

        const enableScroll = () => {
            document.body.style.overflow = '';
        };

        document.addEventListener('mousedown', handleClickOutside);
        disableScroll();
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            enableScroll();
        };
    }, [onClose, logOutRef]);

    return (
        <div css={overlayStyle}>
            <Box ref={overlayRef} css={popupStyle}>
                {!profile ? (
                    <>
                        <p css={css`font-size: 20px`}>Login</p>
                        <p css={css`width: 35vw`}>Please connect your Twitter/X to join the campaign!
                            This is just to verify your account, we <span css={css`font-family: IBM Plex MonoBoldItalic;`}>won't </span>
                            be able to post or make changes.</p>
                        <Button css={css`${closeButtonStyle};`} onClick={loginTwitter}>Connect your Twitter!</Button>
                        <p css={css`width: 35vw;font-size: 13px;margin-top: 15px`}>By using Post2Earn, you agree to our
                            <a href="/legal"
                               css={css`
                                   color: white;
                                   text-decoration: underline;
                                   margin-left: 7px;
                                   margin-right: 7px;
                                   margin-bottom: 4px;
                                   &:visited {
                                       color: white;
                                   }
                              `}>
                            Terms of Conditions
                            </a>
                            and our
                            <a href="/privacy"
                               css={css`
                               color: white;
                               text-decoration: underline;
                                margin-left: 7px;
                                   margin-right: 7px;
                                   margin-bottom: 4px;
                               &:visited {
                                   color: white;
                               }`}>
                                Privacy Policy
                            </a>
                        </p>
                    </>
                ) : (
                    <>
                        <p>Your connected account is: @{profile.username}</p>
                        {profile.canCalculatePoints ? (
                            <p>You can calculate your points now!</p>
                        ) : (
                            <p>You have calculated your points less than 24 hours ago. Wait for a reset and press again</p>
                        )}
                        {/*{profile.walletActivated ? (*/}
                        {/*    <p>You successfully connected your wallet!</p>*/}
                        {/*) : (*/}
                        {/*    <>*/}
                        {/*        <p>To participate fully, consider connecting your Metamask wallet.</p>*/}
                        {/*        <Button css={css`${closeButtonStyle};margin-bottom:10px`} onClick={connectMetamask}>Connect to Metamask</Button>*/}
                        {/*        {errorMessage && <p css={errorStyle}>{errorMessage}</p>}*/}
                        {/*    </>*/}
                        {/*)}*/}
                        {profile.canCalculatePoints ? (
                            <Button css={css`${closeButtonStyle};`} onClick={calculatePoints} disabled={isCalculating}>
                                {isCalculating ? <>{'Wait for an update ~1 minutes'}&nbsp;<CircularProgress size={24} color='inherit'/></> : 'Calculate Points!'}
                            </Button>
                        ) : (
                            <Button disabled={true} css={css`${closeButtonStyle};
                              background-color: rgb(245, 245, 245);color: black`}>
                                {isCalculating ? <>{'Wait for an update ~1 minutes'}&nbsp;<CircularProgress size={24} color='inherit'/></> :
                                    (cooldownTime? cooldownTime : 'Wait for the cooldown to end!')}
                            </Button>
                        )}
                        {errorMessage && <p css={errorStyle}>{errorMessage}</p>}
                    </>
                )}
            </Box>
            {profile && (
                <Box css={logoutBoxStyle} ref={logOutRef}>
                    <Button css={closeButtonStyle} onClick={logout}>Logout</Button>
                </Box>
            )}
        </div>
    );
};

export default Popup;
