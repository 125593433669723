/** @jsxImportSource @emotion/react */
import { FC } from 'react';
import { css } from '@emotion/react';
import {loginButtonStyle} from "../styles/ButtonStyles";
import {hrStyle,mainContentStyles} from "../styles/OtherStyles";
import {TwitterSpan} from "./TwitterSpan";
import {MultipliersList} from "./MultipliersList";
import {ProcessList} from "./ProcessList";
import {GetStartedList} from "./GetStartedList";
import {InteractiveCalculator} from "./InteractiveCalculator";
import adLogo from "../assets/ad.svg";
import yoLogo from "../assets/yo.svg";
import {Image} from "@mui/icons-material";
import roadMap from "../assets/roadmap.svg";
import LinkIcon from '@mui/icons-material/Link';
import Footer from "./Footer";
import {useUserProfile} from "../contexts/UserContext";
import Disclamer from "./Disclamer";
import {Link} from "react-router-dom";


const paragraphStyles = css`
  font-size: 1.2rem;line-height: 1.5;font-family: IBM Plex MonoLight`;


const handleProcessScroll = () => {
    const targetSection = document.getElementById('process');
    if (targetSection) {
        targetSection.scrollIntoView({ behavior: 'smooth' });
    }
};

const handleExplainScroll = () => {
    const targetSection = document.getElementById('explain');
    if (targetSection) {
        targetSection.scrollIntoView({ behavior: 'smooth' ,block: 'center'});
    }
}

const handleDisclaimerScroll = () => {
    const targetSection = document.getElementById('disclaimer');
    if (targetSection) {
        targetSection.scrollIntoView({ behavior: 'smooth' ,block: 'center'});
    }
}


const styles = {
    container: css`
        display: flex;
        align-items: center;
        margin-top: 48px;
    `,
    logo: css`
        width: 25%;
        height: auto;

        @media (max-width: 1050px) {
            width: 15%;
        }
      @media (max-width: 680px) {
        display: none;
      }
    `,
    rectangle: css`
        width: 150px;
        height: 15px;
        background-color: black;
        margin-right: 10px;
        margin-left: 10px;

        @media (max-width: 1050px) {
            margin-top: 10px;
            margin-bottom: 10px;
        }
        @media (max-width: 680px) {
          display: none;
        }
    `,
    text: css`
        font-size: 2.3rem;
        white-space: nowrap;
        font-family: 'IBMPlexMonoItalic';
        width: 25%;
        margin-left: 250px;
        margin-right: 250px;

        @media (max-width: 1050px) {
          margin-left:0px;
          margin-right:0px;
        }
    `,
};

const MainContent: FC = () => {

    const {setPopupOpen} = useUserProfile();

    return (
        <div css = {mainContentStyles}>
            <div css = {css`
                display: flex;
                flex-direction: column;
                align-items: center;
                `}>
                <div css={styles.container}>
                    <div css={css`
                display: flex;
                flex-direction: column;
                align-items: center;
            `}>
                        <div css={css`
                    font-size: 2.5rem;
                    margin-bottom: 0px;
                `}>
                            Post-to-Earn
                        </div>
                        <div css={styles.container}>
                            <img src={adLogo} alt="Logo1" css={styles.logo} />
                            <div css={styles.rectangle}></div>
                            <span css={css`${styles.text};
                                  @media(max-width: 380px) {
                                    font-size: 2rem;
                                  }`
                            }>Airdrop Campaign</span>
                            <div css={css`${styles.rectangle};
                            @media(max-width: 1050px){
                            margin-left: 10vw;
                            }`}></div>
                            <img src={yoLogo} alt="Logo2" css={styles.logo} />
                        </div>
                    </div>
                </div>
                <img css = {css`margin-top:64px; @media (max-width: 680px) {display: none}`} src={roadMap} alt="Road Map" aria-hidden={true}/>
                <div css = {css`display: flex;
                                margin-top: 64px;flex-direction: row;justify-content: center;@media (max-width: 1199.95px) {
                                        width: 100%;
                                }`}>
                    <button css = {css`${loginButtonStyle};
                                 margin-right: 16px;
                                 font-size: 15px;
                                 padding: 10px 20px;
                                 &:hover {
                                          background-color: rgba(0, 0, 0,0.8);
                                          color: rgba(255, 255, 255, 0.8);
                                        }`} onClick = {handleProcessScroll}>
                        Earn Points</button>
                    <Link to='/stats'>
                        <button css = {css`${loginButtonStyle};
                                            color: white;
                                            background-color: rgb(18,18,18);
                                            border: 1px solid rgba(255,255,255,1);
                                            font-size: 15px;
                                            padding: 10px 20px;
                                            margin-right: 16px;
                                            &:hover {
                                              background-color: rgba(0, 0, 0,0.15);
                                              border: 1px solid rgba(255, 255, 255, 0.8);
                                              color: rgba(0, 0, 0, 0.8);
                                            }`
                        }>Stats</button>
                    </Link>
                    <button css = {css`${loginButtonStyle};
                                 font-size: 15px;
                                 padding: 10px 20px;
                                 &:hover {
                                          background-color: rgba(0, 0, 0,0.8);
                                          color: rgba(255, 255, 255, 0.8);
                                        }`} onClick = {handleExplainScroll}>
                        Learn more</button>

                </div>
                <hr css = {css`${hrStyle};`} id={'process'} />
                <div css = {css`margin-top:64px;
                                @media (max-width: 1199.95px) {
                                flex-wrap: wrap;
                                padding: 0px;
                            }`}>
                    <div css = {css`display: flex;
                                    -webkit-box-flex: 1;
                                    flex-grow: 1;
                                    width: 100%;
                                    border-radius: 16px;
                                    justify-content: space-between;
                      
                                @media (max-width: 1199.95px) {
                                        padding-left: 5px;
                                        padding-right: 5px;
                                        flex-wrap: wrap;
                                        justify-content: center;
                                        flex-direction: column;
                                }`}>

                        <div css={css`text-align: start;
                                      background-color: rgb(237, 237, 237);
                                      width:45%;
                                      justify-content: center;
                                      align-items: center;
                                      border-radius: 16px;    
                                      padding: 32px 16px 32px 16px;
                                      border: 2px solid rgba(0,0,0,0.75);
                                  @media (max-width: 1199.95px) {
                                        width: 100%;
                                        margin-bottom: 32px;
                                        align-self: center;
                                }`}>
                            <ProcessList/>
                        </div>
                        <InteractiveCalculator/>
                    </div>
                    <hr css = {css`${hrStyle};`} />
                    <div css={css`display: flex; margin-top: 64px;justify-content: space-between;
                            @media (max-width: 1199.95px) {
                                        padding-left:5px;
                                        padding-right:5px;
                                        flex-wrap: wrap;
                                        justify-content: center;
                                        flex-direction: column;
                                }`}>
                        <div css = {css`text-align: start;
                                      background-color: rgb(237, 237, 237);
                                      justify-content: center;
                                      align-items: center;
                                      border-radius: 16px;    
                                      padding: 32px 32px 32px 32px;
                                      border: 2px solid rgba(0,0,0,0.75);
                                      width: 45%;
                                      @media (max-width: 1199.95px) {
                                        width: 100%;
                                        margin-bottom: 32px;
                                        align-self: center;
                                }
                                      `}>
                                <h3 css = {css`font-size: 2.5rem;
                                               line-height: 1.167;`}>
                                    How points work:</h3>
                                <p css = {paragraphStyles}>
                                    Use Twitter/X account to earn points!
                                    <br/>
                                    <br/>
                                    Reply, quote, or tweet out mentioning <TwitterSpan word = "$ADYO"/> and/or <TwitterSpan word="#ADYO"/> tags and you will automatically be awarded points for each view,
                                    like, reply, repost and bookmark your content receives.
                                    Repost content of others mentioning ADYO tags and claim fixed points rewards.
                                    <br/>
                                    <br/>
                                    Depending on the type of interaction, your points will have a different multiplier:
                                </p>
                                <MultipliersList/>
                                <p css = {paragraphStyles}>
                                    Think of content that has potential to go viral; the bigger the reach, the more tokens you can expect…   </p>
                        </div>

                        <div css = {css`text-align: start;
                                      background-color: rgb(237, 237, 237);
                                      align-items: center;
                                      border-radius: 16px;    
                                      padding: 32px 16px 32px 16px;
                                      border: 2px solid rgba(0,0,0,0.75);display: flex;flex-direction: column;
                                      width: 45%;
                                      @media (max-width: 1199.95px) {
                                        width: 100%;
                                        margin-bottom: 32px;
                                        align-self: center;
                                }`}>
                            <h3 css = {css`font-size: 2.5rem; line-height: 1.167;text-align: center;`}>Rules for posts</h3>
                            <GetStartedList/>
                            <button css = {css`${loginButtonStyle};
                                        color: white;
                                        background-color: rgb(18,18,18);
                                        border: 1px solid rgba(255,255,255,1);
                                        font-size: 15px;
                                        padding: 10px 20px;
                                        width: 90%;
                                        margin-top: 220px;
                                        
                                        &:hover {
                                          background-color: rgba(0, 0, 0,0.15);
                                          border: 1px solid rgba(255, 255, 255, 0.8);
                                          color: rgba(0, 0, 0, 0.8);
                                        }`
                            } onClick = {() => {setPopupOpen(true)}}>Get Started</button>
                        </div>
                    </div>
                </div>
                <Disclamer/>
                <Footer/>
            </div>
        </div>
    );
}

export default  MainContent;