/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import LinkIcon from '@mui/icons-material/Link';
import logo from "../assets/logo.png";
import LanguageIcon from '@mui/icons-material/Language';
import XIcon from '@mui/icons-material/X';
import DiscordIcon from "./DiscordIcon";
import logolong from "../assets/logolong.svg";


const Footer = () => {

    return (
        <>
        <div css = {css`margin-top: 64px;display: flex;margin-bottom: 16px;align-items: center;justify-content: space-evenly;width: 100%;
@media(max-width:1350px ){
                  justify-content: space-between;
                  width: 100%;
                  }`}>
            <div css={css`
            background-color: black;
            display: inline-flex;
            align-items: center;
            color: white;
            border-radius: 5px;
            padding: 5px;
        `}>
                <a href="https://medium.com/@alice_61545/post-to-earn-airdrop-campaign-7e2bf2ec4a0a"
                   target="_blank"
                   rel="noopener noreferrer"
                   css={css`
                   display: flex;
                   align-items: center;
                   margin-right: 20px; /* Space between items */
                   color: white; /* Ensure the link text is white */
                   text-decoration: none; /* Remove underline */
               `}>
                    Article
                    <LinkIcon style={{ color: 'white', transform: 'rotate(305deg)', marginLeft: '8px' }} />
                </a>
                <a href="https://www.ad-yo.com/technology#protocol"
                   target="_blank"
                   rel="noopener noreferrer"
                   css={css`
                       display: flex;
                       align-items: center;
                       color: white; /* Ensure the link text is white */
                       text-decoration: none; /* Remove underline */
                   `}>
                    Docs
                    <LinkIcon style={{ color: 'white', transform: 'rotate(305deg)', marginLeft: '8px' }} />
                </a>
            </div>
            <img src={logolong} alt="logo" css={css`margin-left: 11%;margin-right: 15%;margin-top: 5px;
                  @media(max-width:1350px ){
                display: none;
                  }`}/>
            <div css={css`
                background-color: black;
                display: inline-flex;
                align-items: center;
                color: white;
                border-radius: 5px;
                padding: 5px;
            `}>
                <a href="https://www.ad-yo.com/"
                   target="_blank"
                   rel="noopener noreferrer"
                   css={css`
                       display: flex;
                       align-items: center;
                       color: white;
                       text-decoration: none;
                       margin-right: 10px;
                   `}>
                    <LanguageIcon style={{ color: 'white', marginRight: '5px' }} />
                </a>
                <a href="https://x.com/AdYoAd"
                   target="_blank"
                   rel="noopener noreferrer"
                   css={css`
                       display: flex;
                       align-items: center;
                       color: white;
                       text-decoration: none;
                       margin-right: 10px;
                   `}>
                    <XIcon style={{ color: 'white', marginRight: '5px' }} />
                </a>
                <a href="/"
                   target="_blank"
                   rel="noopener noreferrer"
                   css={css`
                       display: flex;
                       align-items: center;
                       color: white;
                       text-decoration: none;
                   `}>
                    <DiscordIcon style={{ color: 'white' }} />
                </a>
            </div>
        </div>
        <div css={css`
                display: flex;
                margin-top: 20px;
                width: 55%;
                align-self: center;
                justify-content: space-around;
                margin-bottom: 16px;
            `}>
            <a href="/legal"
               css={css`
                       color: black;
                       text-decoration: underline;
                       margin-bottom: 10px;
                       &:visited {
                           color: black;
                       }
                   `}>
                Terms of Conditions
            </a>
            <a href="/privacy"
               css={css`
                       color: black;
                       text-decoration: underline;
                       &:visited {
                           color: black;
                       }
                   `}>
                Privacy Policy
            </a>
        </div>
        </>
    )
}

export default Footer;