import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#3396FF', // --w3m-accent-color
            contrastText: '#FFFFFF', // --w3m-accent-fill-color
        },
        background: {
            default: 'rgb(255,255,255)', // --w3m-color-bg-1
            paper: 'rgb(39,42,42)', // --w3m-color-bg-2
        },
        text: {
            primary: 'rgb(0,0,0)', // --w3m-color-fg-1
            secondary: 'rgb(148,158,158)', // --w3m-color-fg-2
            disabled: 'rgb(110,119,119)', // --w3m-color-fg-3
        },
        success: {
            main: 'rgb(38,181,98)', // --w3m-success-color
        },
        error: {
            main: 'rgb(242, 90, 103)', // --w3m-error-color
        },
    },
    shape: {
        borderRadius: 8, // --w3m-background-border-radius
    },
    typography: {
        fontFamily: '-apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif', // --w3m-font-family
        h1: {
            fontSize: '20px', // --w3m-text-big-bold-size
            fontWeight: 600, // --w3m-text-big-bold-weight
            lineHeight: '24px', // --w3m-text-big-bold-line-height
            letterSpacing: '-0.03em', // --w3m-text-big-bold-letter-spacing
            textTransform: 'none', // --w3m-text-big-bold-text-transform
        },
        subtitle1: {
            fontSize: '12px', // --w3m-text-xsmall-regular-size
            fontWeight: 600, // --w3m-text-xsmall-regular-weight
            lineHeight: '14px', // --w3m-text-xsmall-regular-line-height
            letterSpacing: '-0.03em', // --w3m-text-xsmall-regular-letter-spacing
            textTransform: 'none', // --w3m-text-xsmall-regular-text-transform
        },
        body1: {
            fontSize: '14px', // --w3m-text-small-regular-size
            fontWeight: 600, // --w3m-text-small-regular-weight
            lineHeight: '16px', // --w3m-text-small-regular-line-height
            letterSpacing: '-0.03em', // --w3m-text-small-regular-letter-spacing
            textTransform: 'none', // --w3m-text-small-regular-text-transform
        },
        button: {
            fontSize: '16px', // --w3m-text-medium-regular-size
            fontWeight: 600, // --w3m-text-medium-regular-weight
            lineHeight: '20px', // --w3m-text-medium-regular-line-height
            letterSpacing: '-0.03em', // --w3m-text-medium-regular-letter-spacing
            textTransform: 'none', // --w3m-text-medium-regular-text-transform
        },
    },
    zIndex: {
        modal: 89, // --w3m-z-index
    },
});

export default theme;
