/** @jsxImportSource @emotion/react */
import {css} from "@emotion/react";
import {hrStyle} from "../styles/OtherStyles";


const Disclamer = () => {
    return(
        <div css={css`margin-top: 64px`} id='disclaimer'>
            <h1>Disclaimer</h1>
            <hr css={css`${hrStyle};margin-top:16px;background-color: rgba(0,0,0,0.3)`}/>
            <div>
                ADYO is a suite of decentralized contracts built on a permissionless blockchain.
                ADYO bears no responsibility for any tokens purchased using its contracts.
                All users are taking full responsibility for being aware of the relevant risks involved and
                participating in a token that is entirely independent and not associated with ADYO in any way.
                Social media posts and visible information on the ADYO app in no way count as an endorsement of a
                protocol by the ADYO team, and nothing posted or shared in any ADYO media is a recommendation or financial advice.
            </div>
        </div>
    )
}

export default Disclamer;