/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {SvgArrow} from "./SvgArrow";
import CasinoIcon from '@mui/icons-material/Casino';
import {Icon, SvgIcon} from "@mui/material";

const processBoxStyle = css`
  background-color: rgb(237, 237, 237);
  color: rgb(0, 0, 0);
  margin-inline: 16px;
  padding: 32px;
  border-radius: 16px;
  text-align: start;
  width: 100%;
  @media(max-width: 1199.95px) {
    padding: 0px;
    border-radius: 0px;
  }
`;

const proccessTileStyle = css`
  display: flex;
  flex-direction: column;
`;

const svgStyle = css`
  user-select: none;
  width: 1em;
  height: 1em;
  overflow: hidden;
  display: inline-block;
  box-sizing: border-box;
  text-align: center;
  flex-shrink: 0;
  font-size: 3rem;
  fill: currentColor;`;

const processTitleStyle = css`
  font-family: "IBM Plex MonoLight";
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.6;
  margin-top: 5px;
  margin-bottom: 5px;
  `;

const processDescriptionStyle = css`
  font-family: "IBM Plex MonoLight";
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 1.5;
  margin-top: 5px;`;

export const ProcessList = () => {

    return (
        <div css = {css`display: flex;
                    flex-direction: column;
                    justify-content: center;
                    height: 100%;
                                        @media (max-width: 1199.95px) {
                                                flex-direction: column;
                                                align-items: center;
                                            }`}>
            <div css={css`display: flex;flex-direction: row`}>
                <div css={processBoxStyle}>
                    <div css={proccessTileStyle}>
                        <svg css={svgStyle} viewBox="0 0 24 24">
                            <path d="M2.01 21 23 12 2.01 3 2 10l15 2-15 2z"></path>
                        </svg>
                    </div>
                    <h6 css={processTitleStyle}>Connect to App</h6>
                    <p css={processDescriptionStyle}> link your X account so that we can track
                        your engagement and allocate points
                    </p>
                </div>
                <div css={processBoxStyle}>
                    <div css={proccessTileStyle}>
                        <svg css={svgStyle} viewBox="0 0 24 24">
                            <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-5.97
                            4.06L14.09 6l1.41 1.41L16.91 6l1.06 1.06-1.41 1.41 1.41 1.41-1.06 1.06-1.41-1.4-1.41 1.41-1.06-1.06
                            1.41-1.41-1.41-1.42zm-6.78.66h5v1.5h-5v-1.5zM11.5 16h-2v2H8v-2H6v-1.5h2v-2h1.5v2h2V16zm6.5
                            1.25h-5v-1.5h5v1.5zm0-2.5h-5v-1.5h5v1.5z"></path>
                        </svg>
                    </div>
                    <h6 css={processTitleStyle}>Earn Points</h6>
                    <p css={processDescriptionStyle}>post and repost content mentioning
                        ADYO tags and automatically earn points based on your virality metrics
                    </p>
                </div>
            </div>
            <div css={css`display: flex;flex-direction: row`}>
                <div css={processBoxStyle}>
                    <div css={proccessTileStyle}>
                        <SvgIcon component={CasinoIcon} style={{color: 'black',width:'1em',height:'1em',fontSize:'3em'}}/>
                    </div>
                    <h6 css={processTitleStyle}>Compete!</h6>
                    <p css={processDescriptionStyle}>everyone’s points are visible in the live stats section,
                        aim for a place among top 100 users to win extra points each week!
                    </p>
                </div>
                <div css={processBoxStyle}>
                    <div css={proccessTileStyle}>
                        <svg css={svgStyle} viewBox="0 0 24 24">
                            <path d="m19.83 7.5-2.27-2.27c.07-.42.18-.81.32-1.15.08-.18.12-.37.12-.58
                            0-.83-.67-1.5-1.5-1.5-1.64 0-3.09.79-4 2h-5C4.46 4 2 6.46 2 9.5S4.5 21 4.5
                            21H10v-2h2v2h5.5l1.68-5.59 2.82-.94V7.5h-2.17zM13 9H8V7h5v2zm3 2c-.55 0-1-.45-1-1s.45-1 1-1 1 .45
                            1 1-.45 1-1 1z"></path>
                        </svg>
                    </div>
                    <h6 css={processTitleStyle}>Earn Tokens</h6>
                    <p css={processDescriptionStyle}>Redeem your points for $ADYO tokens
                        at the end of the Post-to-Earn airdrop campaign
                    </p>
                </div>
            </div>
        </div>
    )
}