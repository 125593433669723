/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import FavoriteIcon from '@mui/icons-material/Favorite';
import VisibilityIcon from '@mui/icons-material/Visibility';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import ReplyIcon from '@mui/icons-material/Reply';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';

const listStyles = css`
  display: flex;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
  text-align: left;
  padding: 8px 16px;
`;

const listIconStyle = css`
  min-width: 56px;
  color: rgb(0, 0, 0);
  flex-shrink: 0;
  display: inline-flex;`;

const listContentStyle = css`
  flex: 1 1 auto;
  min-width: 0px;
  margin-top: 6px;
  margin-bottom: 6px;`;

const listContentSpanStyle = css`
  margin: 0px;
  font-family: "IBM Plex Mono";
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  display: block;`;

const listContentPStyle = css`
  margin: 0px;
  font-family: "IBM Plex Mono";
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  color: rgba(0, 0, 0, 0.7);
  display: block;`;


const svgIconStyle = css`
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentcolor;
  flex-shrink: 0;
  font-size: 1.5rem;`


interface TotalMetricsProps {
    views: number;
    likes: number;
    bookmarks: number;
    replies: number;
    quotes: number;
    retweets: number;
    multiplier: number;
    isRetweet: boolean;
    ownRetweet: number;
}

export const TotalMetrics: React.FC<TotalMetricsProps> = ({ views, likes, bookmarks, replies, quotes, retweets,multiplier ,isRetweet, ownRetweet}) => {
    const formatNumber = (num: number) => {
        return new Intl.NumberFormat('en-US').format(num);
    };

    return (
        <ul css={css`margin-top: 16px;padding: 8px 0px;`}>
            { !isRetweet ? (<>
                    <li css={listStyles}>
                        <div css={listIconStyle}>
                            <VisibilityIcon/>
                        </div>
                        <div css={listContentStyle}>
                            <span css={listContentSpanStyle}>{formatNumber(views)}</span>
                            <p css={listContentPStyle}>{1*multiplier} points per view</p>
                        </div>
                    </li>
                <li css={listStyles}>
                    <div css={listIconStyle}>
                        <FavoriteIcon/>
                    </div>
                    <div css={listContentStyle}>
                        <span css={listContentSpanStyle}>{formatNumber(likes*100)}</span>
                        <p css={listContentPStyle}>{100*multiplier} points per like</p>
                    </div>
                </li>
                <li css={listStyles}>
                    <div css={listIconStyle}>
                        <BookmarkIcon/>
                    </div>
                    <div css={listContentStyle}>
                        <span css={listContentSpanStyle}>{formatNumber(bookmarks*150)}</span>
                        <p css={listContentPStyle}>{150*multiplier} points per bookmark</p>
                    </div>
                </li>
                <li css={listStyles}>
                    <div css={listIconStyle}>
                        <ReplyIcon/>
                    </div>
                    <div css={listContentStyle}>
                        <span css={listContentSpanStyle}>{formatNumber(replies*200)}</span>
                        <p css={listContentPStyle}>{200*multiplier} points per reply</p>
                    </div>
                </li>
                <li css={listStyles}>
                    <div css={listIconStyle}>
                        <FormatQuoteIcon/>
                    </div>
                    <div css={listContentStyle}>
                        <span css={listContentSpanStyle}>{formatNumber(quotes*250)}</span>
                        <p css={listContentPStyle}>{250*multiplier} points per quote</p>
                    </div>
                </li>
                <li css={listStyles}>
                    <div css={listIconStyle}>
                        <ReplyAllIcon/>
                    </div>
                    <div css={listContentStyle}>
                        <span css={listContentSpanStyle}>{formatNumber(retweets*300)}</span>
                        <p css={listContentPStyle}>{300*multiplier} points per retweet</p>
                    </div>
                </li>
                </>
            ): (
                <>
                    <li css={listStyles}>
                        <div css={listIconStyle}>
                            <ReplyAllIcon/>
                        </div>
                        <div css={listContentStyle}>
                            <span css={listContentSpanStyle}>{formatNumber(ownRetweet*multiplier*1000)}</span>
                            <p css={listContentPStyle}>{1000*multiplier} points per own retweet</p>
                        </div>
                    </li>
                </>
            )}

        </ul>
    )
}