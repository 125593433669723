/** @jsxImportSource @emotion/react */
import {FC} from 'react';
import {Helmet} from 'react-helmet';
import Navbar from "../components/Navbar";
import {mainContentStyles} from "../styles/OtherStyles";
import UserTable from "../components/UserTable";
import StatsTitle from "../components/StatsTitle";
import Footer from "../components/Footer";

const Stats: FC = () => {
    return (
        <div>
            <Helmet>
                <title>Stats</title>
                <link rel="canonical" href="https://post2earn.co.uk/stats" />
            </Helmet>
            <Navbar/>
            <div css={mainContentStyles}>
                <h1>Top Users</h1>
                <StatsTitle/>
                <UserTable/>
                <Footer/>
            </div>
        </div>
    );
};

export default Stats;